<template>
    <v-dialog
      v-model="dialog"
      width="800"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      style="background-color: blue; border-radius: 30px !important"
    >
      <v-form v-model="valid">
        <v-card flat style="overflow: hidden">
          <v-card-title class="secondary py-1" style="height: 40px">
            <span class="dialog-title"
              >{{ type === "add" ? $t("create") : $t("edit") }}
              {{ $t("hospital") }}</span
            >
            <v-spacer />
            <v-icon small color="primary" @click="$emit('close')"
              >mdi-window-close</v-icon
            >
          </v-card-title>
          <v-card-text color="primary" class="mt-5">
            <span class="d-flex align-end justify-end px-3">
                  <span style="color: red">{{ $t("requiredFields") }}
                  </span>
              </span>
          </v-card-text>

          <v-row class="px-6">
            <v-row class="pl-6 pb-3 pt-3">
              <span style="font-size: 18px">{{$t('hospital')}} {{$t('details')}}</span>
            </v-row>
            <v-col cols="12">
              <v-row dense cols="12" class="px-1 mb-2">
                  <v-col cols="12" lg="12" md="12" sm="12">
                      <v-select
                          v-model="hospital.medicalInstitutionId"
                          :items="medicalInstitutions"
                          item-text="institutionName"
                          item-value="id"
              :label="$t('medicalInstitution')"
                          prepend-inner-icon="mdi-office-building mr-1"
                          dense
                          background-color="#E8E8E8"
                          rounded
                          outlined
                          style="border-radius: 12px !important; height: 40px"
                          :rules="[(rule) => !!rule || '']"
                      ></v-select>
                  </v-col>
              </v-row>
              <v-row dense cols="12" class="px-1 mb-2">
                  <v-col cols="12" lg="6" md="6" sm="12">
                      <v-text-field
                          v-model="hospital.hospitalName"
              :label="$t('office-name')"
                          prepend-inner-icon="mdi-hospital-building mr-1"
                          dense
                          background-color="#E8E8E8"
                          rounded
                          outlined
                          style="border-radius: 12px !important; height: 40px"
                          :rules="[(rule) => !!rule || '']"
                      ></v-text-field>
                  </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="hospital.contactPerson"
            :label="$t('contact-person')"
                      prepend-inner-icon="mdi-account mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                  </v-col>
              </v-row>
              <v-row dense cols="12" class="px-1 mb-2">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                      ref="phone"
                      :name="$t('phone')"
                      rules="required|phone"
                      v-slot="{ errors }"
                  >
                    <v-text-field
                      ref="phoneNumber"
                      v-model="phoneNumber"
                      validate-on-blur
                      :label="$t('phone-number')"
                      prepend-inner-icon="mdi-cellphone"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                    ref="email"
                    :name="$t('email')"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="hospital.contactEmail"
                      :label="$t('contact-email')"
                      prepend-inner-icon="mdi-email mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :error-messages="(emailError = errors)"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense cols="12" class="px-1 mb-2">
                  <v-col cols="12" lg="12" md="12" sm="12">
                    <validation-provider
                        name="Address"
                        rules="required"
                        v-slot="{ errors }">
                      <v-text-field
                          ref="address"
                          v-model="hospital.address"
                          validate-on-blur
                          :label="$t('a-s-p-c-c-c')"
                          prepend-inner-icon="mdi-map-marker mr-1"
                          dense
                          background-color="#E8E8E8"
                          rounded
                          outlined
                          style="border-radius: 12px !important; height: 40px"
                          :error-messages="errors">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
              </v-row>
              <v-row dense class="px-1">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                      name="City"
                      rules="required"
                      v-slot="{ errors }">
                    <v-text-field
                        v-model="address_info.city"
                        :label="$t('city')"
                        prepend-inner-icon="mdi-map-marker mr-4"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        :error-messages="errors">
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                      name="Zip Code"
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-text-field
                        v-model="address_info.postal_code"
                        :label="$t('zip-code')"
                        prepend-inner-icon="mdi-map-marker mr-4"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        :error-messages="errors">
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense class="pt-1 px-1">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                      name="State"
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-text-field
                        v-model="address_info.state"
                        :label="$t('state')"
                        prepend-inner-icon="mdi-map-marker mr-4"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                      name="Country"
                      rules="required"
                      v-slot="{ errors }">
                    <v-text-field
                        v-model="address_info.country"
                        :label="$t('country')"
                        prepend-inner-icon="mdi-map-marker mr-4"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        :error-messages="errors">
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense cols="12" class="px-1">
                  <v-col cols="12" lg="12" md="12" sm="12">
                      <v-textarea
                      v-model="hospital.description"
            :label="$t('description')"
                      prepend-inner-icon="mdi-card-text mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important"
                      :rules="[(rule) => !!rule || '']"
                      ></v-textarea>
                  </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions class="px-6">
            <Alert
              class="mt-4"
              v-if="showErrors"
              :title="title"
              :type="alertType"
            ></Alert>
            <v-spacer />
            <div class="my-3">
              <v-btn class="table-cancel-button" @click="$emit('close')">
                <span color="black--text">{{ $t("cancel") }}</span>
              </v-btn>
              <v-btn
                  class="table-create-button mx-2"
                  :loading="loading"
                  :disabled="loading || !valid"
                  @click="loader = 'loading'"
              >
                  <span>{{ $t("save") }}</span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </template>
<script>
import { mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import Alert from '@/views/Client/components/AlertDialog.vue';
import Hospital from '../../data/models/Hospital';

export default {
	components: {
		Alert,
	},
	props: ['dialog', 'type', 'selectedHospital'],
	data () {
		return {
			loader: null,
			loading: false,
			showErrors: false,
			title: 'Something went wrong',
			alertType: false,
			valid: false,
			object: {
				address: '',
				addressLatitude: 0,
				addressLongitude: 0,
				city: null,
				contactEmail: null,
				contactPerson: null,
				contactPhone: null,
				country: null,
				description: null,
				hospitalName: null,
				hospitalSpetializationId: null,
				id: null,
				medicalInstitutionData: null,
				medicalInstitutionId: null,
				state: null,
				zip: null,
			},
			address_info: {
				address: '',
				postal_code: '',
				city: '',
				state: '',
				country: '',
				lat: 0,
				lng: 0,
			},
			countries: [],
			medicalInstitutionId: -1,
			medicalInstitutions: [],
			hospitalName: '',
			contactPerson: '',
			contactEmail: '',
			description: '',
			contactPhone: '',
			phoneData: {
				phone: '',
				phoneCodeModel: 'US 1',
			},
			emailError: false,
			validationErrors: [],
			phoneError: false,
			phoneNumber: null,
		};
	},
	computed: {
		...mapState({
			medicalInstitutionsFromRepo: (state) =>
				state.medicalInstitutions.medicalInstitutions,
		}),
		hospital () {
			if (this.type === 'edit') {
				return this.selectedHospital;
			} else return this.object;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createHospital()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		phoneNumber (val) {
			if (!isValidPhoneNumber(val)) {
				this.phoneError = true;
			} else {
				this.phoneError = false;
			}
			this.phoneNumber = new AsYouType().input(val);
		},
	},
	async mounted () {
		await this.getMedicalInstitutions();
		if (this.type === 'edit') {
			this.phoneNumber = this.hospital?.contactPhone;
		} else if (this.type === 'add') {
			this.phoneNumber = '+1'; // Corrected assignment operator here
		}
		if (this.type === 'edit') {
			this.addressData();
			if (!isValidPhoneNumber(this.phoneNumber)) {
				this.phoneError = true;
			}
		}
	},
	methods: {
		async addressData () {
			this.address_info = {
				address: this.hospital.address,
				postal_code: this.hospital.zip,
				city: this.hospital.city,
				state: this.hospital.state,
				country: this.hospital.country,
				lat: this.hospital.addressLatitude,
				lng: this.hospital.addressLongitude,
			};
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch(
				'googleAPI/getTimeZone',
				object,
			);
			return timeZone;
		},
		// async locationDetails () {
		// 	if (this.hospital.address) {
		// 		this.valid = false;
		// 		const geoLocation = await this.$store.dispatch(
		// 			'googleAPI/getGeoLocation',
		// 			this.hospital.address,
		// 		);
		// 		this.valid = true;
		// 		if (geoLocation === undefined) {
		// 			this.validationErrors.push('address');
		// 			this.address_info = {
		// 				address: '',
		// 				postal_code: '',
		// 				city: '',
		// 				state: '',
		// 				country: '',
		// 				lat: 0,
		// 				lng: 0,
		// 			};
		// 			return;
		// 		}
		// 		const addressComponent = geoLocation.address_components;
		// 		this.address_info.lat = geoLocation.geometry.location.lat;
		// 		this.address_info.lng = geoLocation.geometry.location.lng;
		// 		this.formattedAddress = geoLocation.formatted_address;
		// 		this.address_info.address = this.formattedAddress;
		// 		addressComponent.forEach((ac) => {
		// 			if (ac.types.includes('administrative_area_level_1')) {
		// 				this.address_info.state = ac.long_name;
		// 			}
		// 			if (ac.types.includes('locality')) {
		// 				this.address_info.city = ac.long_name;
		// 			}
		// 			if (ac.types.includes('country')) {
		// 				this.address_info.country = ac.long_name;
		// 			}
		// 			if (ac.types.includes('postal_code')) {
		// 				this.address_info.postal_code = ac.long_name;
		// 			}
		// 		});
		// 		if (this.address_info.lat !== null) {
		// 			const tz = await this.timeZone(
		// 				this.address_info.lat,
		// 				this.address_info.lng,
		// 			);
		// 			if (tz) {
		// 				this.timezone = tz.zoneName;
		// 			}
		// 		}
		// 	}
		// },
		async locationDetails () {
			if (this.hospital.address) {
				this.waiting = true;
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.hospital.address,
				);
				this.waiting = false;
				if (!geoLocation) {
					this.validationErrors.push('address');
					this.address_info = {
						address: '',
						postal_code: '',
						city: '',
						state: '',
						country: '',
						lat: 0,
						lng: 0,
					};
					return;
				}
				const addressComponents = geoLocation.address;
				this.address_info.lat = geoLocation.lat;
				this.address_info.lng = geoLocation.lon;
				this.formattedAddress = geoLocation.display_name;
				this.address_info.address = this.formattedAddress;
				this.address_info.city = addressComponents.city;
				this.address_info.state = addressComponents.state;
				this.address_info.country = addressComponents.country;
				this.address_info.postal_code = addressComponents.postcode;
				if (this.address_info.lat !== null) {
					const tz = await this.timeZone(
						this.address_info.lat,
						this.address_info.lng,
					);
					if (tz) {
						this.timezone = tz.zoneName;
					}
				}
			}
		},
		checkValid () {
			this.validationErrors = [];
			if (this.phoneError) {
				this.validationErrors.push('phone');
			}
			if (this.emailError.length > 0) {
				this.validationErrors.push('email');
			}
			if (this.address_info.address === '') {
				this.validationErrors.push('address');
			}
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
		async createHospital () {
			await this.locationDetails();
			const valid = this.checkValid();
			if (!valid) {
				this.title = 'Provided data is not valid: ' + this.validationErrors.toString();
				this.alertType = 'error';
				this.showErrors = true;
			}
			if (valid) {
				this.validationErrors = [];
				this.showErrors = false;
				const body = new Hospital(
					this.hospital.medicalInstitutionId,
					this.hospital.hospitalName,
					this.hospital.description,
					this.hospital.contactPerson,
					this.phoneNumber,
					this.hospital.contactEmail,
					this.address_info.address,
					this.address_info.postal_code,
					this.address_info.city,
					this.address_info.state,
					this.address_info.country,
					this.address_info.lat,
					this.address_info.lng,
				);
				switch (this.type) {
				case 'add':
					this.createhospital(body);
					break;
				case 'edit':
					this.updatehospital(body);
					break;
				default:
					break;
				}
			}
		},
		async createhospital (body) {
			await this.$store
				.dispatch('hospitals/createHospital', body)
				.then((res) => {
					this.$emit('false', res);
				});
		},
		async updatehospital (body) {
			body.id = this.hospital.id;
			await this.$store.dispatch('hospitals/editHospital', body).then((res) => {
				this.$emit('false', res);
			});
		},
		async getMedicalInstitutions () {
			await this.$store
				.dispatch('medicalInstitutions/getMedicalInstitutions')
				.then(() => {
					this.medicalInstitutions = this.medicalInstitutionsFromRepo;
					this.loading = false;
				});
		},
	},
};
</script>
